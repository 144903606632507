import React from 'react'
import { useState } from 'react';
// import { Link } from "gatsby";
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";


const BenefitsTab = ({ blok }) => {
    // console.log(blok)

    const [activeTab, setActiveTab] = useState(0)
    const [activeTabDisplay, setActiveTabDisplay] = useState(0)

    const tabMenu = blok.program_list.map((item, i) =>
        <li className={`${activeTab == i ? "is-active" : ""}`} key={i}>
            <a
                onClick={() => { setActiveTabDisplay(i); setActiveTab(i) }}
            >
                <span>{item.name}</span>
            </a>
        </li>
    )

    const tabContent = blok.program_list.map((item, i) =>
        <div
            className=""
            value={i}
            key={i}
            style={activeTabDisplay == i ? { display: 'block' } : { display: 'none' }}
        >
            {item.programs_list.map((item, i) =>
                <div
                className='pb-6'
                key={i}
                >

                    <h3 className='title is-4'>{item.name}</h3>
                    <hr></hr>
                    <div className="content">{render(item.description)}</div>
                </div>)}
        </div>
    )

    // const tabContent = blok.program_list.map((item, i) =>
    //     <div
    //         className="notification content has-background-white"
    //         value={i}
    //         // style={listDisplay == i ? { display: 'block' } : { display: 'none' }}
    //     >
    //         {render(item.description)}
    //     </div>
    // )

    return (
        <div {...sbEditable(blok)}>
            <div className="section" style={{ backgroundColor: "#fff" }}>
                <div className="container mt-6">
                    <div className="columns is-vcentered">
                        <div className="column is-full is-size-5">
                            <div className="tabs mb-0 is-centered is-boxed is-medium"
                            style={{ backgroundColor: "#C9E3E0"}}
                            >
                                <ul>
                                    {tabMenu}
                                </ul>
                            </div>
                            <div
                                className='notification'
                                style={{ backgroundColor: "#C9E3E0", height: "500px", overflowY: "scroll" }}
                            >
                                {tabContent}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BenefitsTab;